<template>
  <v-sheet class="d-flex align-center justify-center" :height="height">
    <v-progress-circular indeterminate color="blue" size="40" width="2" />
  </v-sheet>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    height: {
      type: String,
      default: '80',
    },
  },
}
</script>
