<template>
  <v-autocomplete
    v-model="coords"
    :items="items"
    outlined
    dense
    clearable
    hide-details
    append-icon=""
    item-text="city"
    item-value="value"
    :no-data-text="$t(noDataText)"
    :label="$t('label.city')"
    :search-input.sync="search"
    :error="!!search ? false : isError"
    no-filter
  />
</template>

<script>
import axios from 'axios'

export default {
  name: 'MapAutocomplete',
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    isReset: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: 'service.listIsEmpty',
    },
  },
  data: () => ({
    search: null,
    coords: null,
    items: [],
  }),
  watch: {
    search(value) {
      if (!value) {
        this.items = []
        return
      }
      setTimeout(() => {
        this.searchHandler(value)
      }, 200)
    },
    isReset(value) {
      if (value) this.reset()
    },
  },
  methods: {
    async searchHandler(value) {
      const resp = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.VUE_APP_MAP_KEY}&address=${value}&language=${this.$i18n.locale}`
      )
      if (resp) {
        const {results} = resp.data
        this.items = results
          .map((a) => {
            const {location} = a.geometry
            return {
              place_id: a.place_id,
              value: location,
              city: a.formatted_address ?? '',
            }
          })
          .filter((b) => b !== undefined)
      }

      if (this.coords) {
        const current = this.items.find((a) => JSON.stringify(a.value) === JSON.stringify(this.coords))
        const formData = {
          coords: this.coords,
          city: current.city,
          place_id: current.place_id,
        }
        this.$emit('setCoords', formData)
      }
    },
    reset() {
      this.items = []
      this.coords = null
      this.search = null
    },
  },
}
</script>
