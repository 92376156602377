<template>
  <v-form v-model="isValid" ref="form" lazy-validation>
    <v-expansion-panels :value="openPanels" multiple>
      <v-expansion-panel
        v-for="panel in panels"
        :key="panel.id"
        @click.once="loadDictionary(panel.type, panel.name)"
      >
        <v-expansion-panel-header class="expansion-title">
          <div class="expansion-start">
            {{ $t('panels.' + panel.type) }}
            <template v-if="panel.valid">
              <v-fade-transition leave-absolute>
                <v-badge
                  color="red"
                  dot
                  class="flex-grow-0 pl-5 pb-5"
                  :value="badgeTypes.some((a) => a === panel.type)"
                />
              </v-fade-transition>
            </template>
          </div>

          <div v-if="panel.withPanelInfo" class="expansion-middle px-15">
            <PanelInfoSelected :panel="panel" :formData="formData" />
          </div>

          <template #actions>
            <v-icon color="black"> $expand </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="panel.type === 'basic'">
            <v-col v-if="isAdmin || isManager" cols="12" md="6" xl="3">
              <CustomSelect
                v-model="formData.userId"
                id="userId"
                name="userId"
                :label="$t('label.userId')"
                :rules="mapRules('userId')"
                :items="userIdItems"
              />
            </v-col>
            <v-col cols="12" md="6" xl="3">
              <CustomInput
                v-model="formData.name"
                id="name"
                name="name"
                :label="$t('label.name')"
                :rules="mapRules('name')"
              />
            </v-col>
            <v-col cols="12" md="6" xl="3">
              <CustomSelect
                v-model="formData.status"
                id="status"
                name="status"
                :label="$t('label.status')"
                :rules="mapRules('status')"
                :items="statusItems"
              />
            </v-col>
          </v-row>
          <div v-else-if="panel.type === 'geo'">
            <GeoType
              :panel="panel"
              :dictionary="dictionary"
              :total="formData[panel.country].length"
              :selectedItems="formData[panel.country]"
              :name="panel.country"
              :isShowLoader="loading"
              :dataPagination="dataPagination"
              @select="selectItems"
              @delete="deleteItem"
              @clearAll="clearAllItems"
              @more="loadMoreItems"
              @loadCountry="loadCountryItems"
              @loadMoreCountry="loadMoreCountryItems"
            />
            <div class="px-2 py-5">
              <Map @addMarkers="addMapMarkers" />
              <v-row class="mt-5">
                <v-col v-if="formData.geoBoundariesWhiteItems.length" cols="12" sm="6">
                  <p>{{ $t('title.targetLocations') }}:</p>
                  <div class="custom-virtual-scroll__container" :style="{maxHeight: 210 + 'px'}">
                    <v-list v-for="item in formData.geoBoundariesWhiteItems" :key="item.id" class="py-1">
                      <v-list-item>
                        <span>{{ item.city }} ({{ item.radius }}{{ $t('units.km') }})</span>
                        <v-icon
                          color="red"
                          class="ml-10"
                          @click="deleteItem('geoBoundariesWhiteItems', 'id', item)"
                        >
                          mdi-close-circle-outline
                        </v-icon>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-col>
                <v-col v-if="formData.geoBoundariesBlackItems.length" cols="12" sm="6">
                  <p>{{ $t('title.excludedLocations') }}:</p>
                  <div class="custom-virtual-scroll__container" :style="{maxHeight: 210 + 'px'}">
                    <v-list v-for="item in formData.geoBoundariesBlackItems" :key="item.id" class="py-1">
                      <v-list-item>
                        <span>{{ item.city }} ({{ item.radius }}{{ $t('units.km') }})</span>
                        <v-icon
                          color="red"
                          class="ml-10"
                          @click="deleteItem('geoBoundariesBlackItems', 'id', item)"
                        >
                          mdi-close-circle-outline
                        </v-icon>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <PanelType
            v-else-if="
              panel.type === 'device' ||
              panel.type === 'iabWhite' ||
              panel.type === 'carrier' ||
              panel.type === 'position' ||
              panel.type === 'connection'
            "
            :panel="panel"
            :dictionary="dictionary"
            :selectedItems="formData[panel.name]"
            :name="panel.name"
            :isShowLoader="loading"
            :dataPagination="dataPagination"
            :blockHeight="parsePanelInfo(panel.type).blockHeight"
            @select="selectItems"
            @search="searchItem"
            @delete="deleteItem"
            @clearAll="clearAllItems"
            @more="loadMoreItems"
          />
          <PanelType
            v-else-if="panel.type === 'domain' || panel.type === 'page'"
            :panel="panel"
            :dictionary="dictionary"
            :selectedItems="formData[panel.name]"
            :name="panel.name"
            :blockHeight="parsePanelInfo(panel.type).blockHeight"
            :label="parsePanelInfo(panel.type).label"
            inputType="add"
            @select="selectItems"
            @add="addItem"
            @load="loadFiles"
            @delete="deleteItem"
            @clearAll="clearAllItems"
            @more="loadMoreItems"
          />
          <ModelType
            v-else-if="panel.type === 'model'"
            :panel="panel"
            :dictionary="dictionary"
            :selectedItems="formData[panel.name]"
            :name="panel.name"
            :isShowLoader="loading"
            :dataPagination="dataPagination"
            :tabs="modelTabs"
            @select="selectItems"
            @search="searchItem"
            @delete="deleteItem"
            @clearAll="clearAllItems"
            @more="loadMoreItems"
            @changeTab="changeActiveTab"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn color="expansionGrey" block class="text-center pa-5 my-10" @click="openExceptions">
      {{ $t('service.exceptions') }}
      <v-icon class="pl-10">
        {{ isException ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </v-btn>
    <v-slide-y-transition hide-on-leave>
      <v-expansion-panels v-if="isException" multiple>
        <v-expansion-panel
          v-for="panel in exceptionPanelItems"
          :key="panel.id"
          @click.once="loadDictionary(panel.type, panel.name)"
        >
          <v-expansion-panel-header class="expansion-title">
            <div class="expansion-start">
              {{ $t('panels.' + panel.type) }}
              <template v-if="panel.valid">
                <v-fade-transition leave-absolute>
                  <v-badge
                    color="red"
                    dot
                    class="flex-grow-0 pl-5 pb-5"
                    :value="badgeTypes.some((a) => a === panel.type)"
                  />
                </v-fade-transition>
              </template>
            </div>

            <div v-if="panel.withPanelInfo" class="expansion-middle px-15">
              <PanelInfoSelected :panel="panel" :formData="formData" />
            </div>

            <template #actions>
              <v-icon color="black"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PanelType
              v-if="panel.type === 'osBlack' || panel.type === 'iabBlack'"
              :panel="panel"
              :dictionary="dictionary"
              :selectedItems="formData[panel.name]"
              :name="panel.name"
              :blockHeight="parsePanelInfo(panel.type).blockHeight"
              :label="parsePanelInfo(panel.type).label"
              :actionFiled="parsePanelInfo(panel.type).actionFiled"
              :deleteFiled="parsePanelInfo(panel.type).deleteFiled"
              :isShowLoader="loading"
              :dataPagination="dataPagination"
              @select="selectItems"
              @search="searchItem"
              @delete="deleteItem"
              @clearAll="clearAllItems"
              @more="loadMoreItems"
            />
            <PanelType
              v-else-if="
                panel.type === 'domainBlack' || panel.type === 'ipBlack' || panel.type === 'pageBlack'
              "
              :panel="panel"
              :dictionary="dictionary"
              :selectedItems="formData[panel.name]"
              :name="panel.name"
              :blockHeight="parsePanelInfo(panel.type).blockHeight"
              :label="parsePanelInfo(panel.type).label"
              inputType="add"
              @select="selectItems"
              @add="addItem"
              @load="loadFiles"
              @delete="deleteItem"
              @clearAll="clearAllItems"
              @more="loadMoreItems"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-slide-y-transition>

    <v-btn color="expansionGrey" block class="text-center pa-5 my-10" @click="openAdvanced">
      {{ $t('service.advanced') }}
      <v-icon class="pl-10">
        {{ isAdvanced ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </v-btn>
    <v-slide-y-transition hide-on-leave>
      <v-expansion-panels v-if="isAdvanced" multiple>
        <v-expansion-panel
          v-for="panel in advancedPanelItems"
          :key="panel.id"
          @click.once="loadDictionary(panel.type, panel.name)"
        >
          <v-expansion-panel-header class="expansion-title">
            <div class="expansion-start">
              {{ $t('panels.' + panel.type) }}
              <template v-if="panel.valid">
                <v-fade-transition leave-absolute>
                  <v-badge
                    color="red"
                    dot
                    class="flex-grow-0 pl-5 pb-5"
                    :value="badgeTypes.some((a) => a === panel.type)"
                  />
                </v-fade-transition>
              </template>
            </div>

            <div v-if="panel.withPanelInfo" class="expansion-middle px-15">
              <PanelInfoSelected :panel="panel" :formData="formData" />
            </div>

            <template #actions>
              <v-icon color="black"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PanelType
              v-if="panel.type === 'ipWhite'"
              :panel="panel"
              :dictionary="dictionary"
              :selectedItems="formData[panel.name]"
              :name="panel.name"
              :blockHeight="parsePanelInfo(panel.type).blockHeight"
              :label="parsePanelInfo(panel.type).label"
              inputType="add"
              @select="selectItems"
              @add="addItem"
              @load="loadFiles"
              @delete="deleteItem"
              @clearAll="clearAllItems"
              @more="loadMoreItems"
            />
            <CheckboxType
              v-else-if="panel.type === 'safety'"
              :panel="panel"
              :items="safetyItems"
              :formData="formData"
              @change="changeItem"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-slide-y-transition>
  </v-form>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import PanelType from '@/views/targets/part/PanelType'
import GeoType from '@/views/targets/part/GeoType'
import ModelType from '@/views/targets/part/ModelType'
import CheckboxType from '@/views/targets/part/CheckboxType'
import PanelInfoSelected from '@/views/targets/part/PanelInfoSelected'
import Map from '@/views/targets/map/Map'

export default {
  name: 'TargetPanels',
  components: {PanelType, GeoType, Map, ModelType, CheckboxType, PanelInfoSelected},
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    panels: {
      type: Array,
      default: () => [],
    },
    exceptionPanelItems: {
      type: Array,
      default: () => [],
    },
    advancedPanelItems: {
      type: Array,
      default: () => [],
    },
    statusItems: {
      type: Array,
      default: () => [],
    },
    dictionaryTypes: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isManager: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isValid: false,
    type: 'basic',
    name: null,
    isException: false,
    isAdvanced: false,
    openPanels: [0],
    page: 0,
    search: '',
    callNames: [
      'geo',
      'device',
      'iabWhite',
      'position',
      'carrier',
      'model',
      'connection',
      'osBlack',
      'iabBlack',
      'safety',
    ],
    iabWhiteItems: [],
    iabBlackItems: [],
    safetyItems: {},
    badgeTypes: [],
    isCheckValidation: false,
    activeModelTab: 'Android',
    modelTabs: [
      {
        id: 0,
        type: 'Android',
        value: 'Android',
      },
      {
        id: 1,
        type: 'iOS',
        value: 'iOS',
        isServer: true,
      },
      {
        id: 2,
        type: 'modelOther',
        value: 'Other',
        isServer: true,
        isTranslate: true,
      },
    ],
  }),
  computed: {
    ...mapState({
      isCheckLocale: (state) => state.isCheckLocale,
      dictionary: (state) => state.dictionary,
      dataPagination: (state) => state.dictionary.dataPagination,
      loading: (state) => state.dictionary.loading,
      targetFiles: (state) => state.file.targetFiles,
      userIdItems: (state) => state.user.userIdItems,
    }),
  },
  mounted() {
    if (this.isAdmin || this.isManager) this.loadClients()
  },
  methods: {
    ...mapActions({
      getDictionary: 'dictionary/GET_DICTIONARY',
      getCountry: 'dictionary/GET_COUNTRY',
      fileUpload: 'file/FILE_UPLOAD',
      getClients: 'user/GET_CLIENTS',
    }),
    async loadClients() {
      await this.getClients()
    },
    async loadData(type, name = null, isStart = false) {
      const {sort, name: arrayName} = this.dictionaryTypes[type]
      if (this.callNames.includes(type)) {
        let params = {}
        if (type === 'model') {
          params = {value: this.search, type: this.activeModelTab}
        } else {
          params = {value: this.search, sort}
        }

        await this.getDictionary({
          type,
          page: this.page,
          params,
          isStart,
          activeTab: this.activeModelTab,
        })
      }
      const nameValue = type === 'model' ? this.activeModelTab : name

      if (name) {
        const arrayValue = type === 'model' ? this.activeModelTab : arrayName
        this[nameValue] = this.dictionary[arrayValue]
      }
    },
    parsePanelInfo(type) {
      switch (type) {
        case 'device':
          return {blockHeight: 300}
        case 'position':
        case 'connection':
          return {blockHeight: 425}
        case 'domain':
          return {label: 'service.labelDomain', blockHeight: 250}
        case 'page':
          return {label: 'service.labelPage', blockHeight: 250}
        case 'osBlack':
          return {blockHeight: 250}
        case 'iabBlack':
          return {label: 'service.labelIp', actionFiled: 'id', deleteFiled: 'id'}
        case 'domainBlack':
          return {label: 'service.labelDomain', blockHeight: 250}
        case 'ipBlack':
          return {label: 'service.labelIp', blockHeight: 250}
        case 'pageBlack':
          return {label: 'service.labelPage', blockHeight: 250}
        case 'ipWhite':
          return {label: 'service.labelIp', blockHeight: 250}
        default:
          return {label: 'service.labelByWord', actionFiled: 'value', deleteFiled: 'value', blockHeight: 500}
      }
    },
    async loadDictionary(type, name) {
      this.page = 0
      this.type = type
      this.name = name
      this.search = null
      this.$nextTick(async () => {
        await this.loadData(this.type, this.name, true)
      })
    },
    mapRules(type) {
      switch (type) {
        case 'userId':
          return this.isCheckLocale ? [(v) => !!v || this.$t('rules.userIdRequired')] : []
        case 'name':
          return this.isCheckLocale ? [(v) => !!v || this.$t('rules.nameRequired')] : []
        case 'status':
          return this.isCheckLocale ? [(v) => !!v || this.$t('rules.statusRequired')] : []
        default:
          return []
      }
    },
    validate() {
      this.isCheckValidation = true
      this.checkErrorValidation()
      if (this.$refs.form.validate() && !this.badgeTypes.length) {
        return this.$refs.form.validate()
      }
    },
    checkErrorValidation() {
      this.panels.forEach((a) => {
        if (a.valid) {
          const name = a.country ? 'country' : 'name'
          if (this.formData[a[name]]?.length === 0) {
            if (this.badgeTypes.every((b) => b !== a.type)) {
              this.badgeTypes.push(a.type)
            }
          } else {
            this.badgeTypes = this.badgeTypes.filter((c) => c !== a.type)
          }
        }
      })
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    selectItems(name, field, item) {
      if (this.formData[name]?.some((a) => a[field] === item[field])) {
        this.formData[name] = this.formData[name].filter((a) => a[field] !== item[field])
      } else {
        this.formData[name].push(item)
      }
    },
    searchItem(name, type, value) {
      const {view} = this.dictionaryTypes[type]
      if (view === 'iab') {
        const filteredItems = this[name].filter((a) =>
          this.$i18n.t(a.text).toLowerCase().includes(value.toLowerCase())
        )
        const uniqueRootValues = new Set(filteredItems.map((a) => a.value.replace(/-.*/, '')))
        const filteredRootItems = this[name].filter((a) => uniqueRootValues.has(a.value))
        const filteredItemsWithRootItems = [...filteredRootItems, ...filteredItems]
        this.dictionary[name] = value ? [...new Set(filteredItemsWithRootItems)] : this[name]
      } else {
        this.page = 0
        this.type = type
        this.search = value
        this.loadData(type, name, true)
      }
    },
    addItem(name, type, value) {
      this.formData[name].push({
        text: value,
        value: this.$uuidv4(),
      })
    },
    deleteItem(name, field, item) {
      this.formData[name] = this.formData[name].filter((a) => a[field] !== item[field])
    },
    clearAllItems(name) {
      this.formData[name] = []
    },
    loadMoreItems(type, name, isTab) {
      if (isTab) this.activeModelTab = name
      const typeValue = type === 'model' ? name : type
      if (this.loading || this.dataPagination[typeValue].last) return
      this.page += 1
      this.loadData(type, name)
    },
    addMapMarkers(markers) {
      this.formData.geoBoundariesWhiteItems = markers.filter((a) => a.location === 'target')
      this.formData.geoBoundariesBlackItems = markers.filter((a) => a.location === 'exception')
    },
    async loadFiles(name, type, file) {
      const formData = new FormData()
      formData.append('file', file)
      await this.fileUpload({type, formData})
      const mapObj = this.targetFiles[type].map((a) => {
        return {
          text: a,
          value: this.$uuidv4(),
        }
      })
      this.formData[name] = [...this.formData[name], ...mapObj]
    },
    openExceptions() {
      this.isException = !this.isException
    },
    openAdvanced() {
      this.isAdvanced = !this.isAdvanced
    },
    changeItem(name, value) {
      if (this.formData[name].some((a) => a === value)) {
        this.formData[name] = this.formData[name].filter((a) => a !== value)
      } else {
        this.formData[name].push(value)
      }
    },
    async changeActiveTab(tab) {
      this.activeModelTab = tab
      this.page = 0
      this.$nextTick(async () => {
        await this.loadData(this.type, this.name, true)
      })
    },
    async loadCountryItems(value, type, countryCode, isStart = true) {
      if (isStart) this.page = 0
      let url = ''
      if (type === 'country') {
        url = 'geo-section-country/read-all'
      } else if (type === 'region') {
        url = 'geo-section-country-region/read-all'
      } else if (type === 'city') {
        url = 'geo-section-country-region-city/read-all'
      }

      await this.getCountry({value, page: this.page, isStart, url, countryCode})
      this[value] = this.dictionary[value]
    },
    loadMoreCountryItems(value, type, countryCode) {
      if (this.loading || this.dataPagination[value].last) return
      this.page += 1
      this.loadCountryItems(value, type, countryCode, false)
    },
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        if (this.isCheckValidation) this.checkErrorValidation()
      },
    },
    badgeTypes(items) {
      this.isCheckValidation = items.length !== 0
    },
  },
}
</script>
<style lang="scss" scoped>
.v-expansion-panel-header {
  .expansion-start {
    flex: 0 1 400px;
  }

  .expansion-middle {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.2;
  }
}
</style>
