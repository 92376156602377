<template>
  <v-sheet :height="blockHeight" class="overflow-y-auto">
    <v-tabs
      v-model="tab"
      mobile-breakpoint="0"
      slider-color="blue"
      color="blue"
      background-color="transparent"
      class="mb-5"
    >
      <v-tab v-for="tab in tabs" :key="tab.type">
        {{ $t('panels.' + tab.type) }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="py-5 px-10">
      <v-tab-item v-for="item in tabs" :key="item.id">
        <v-row v-if="items[item.name] && items[item.name].length">
          <v-col
            v-for="(checkbox, i) in items[item.name]"
            :key="'checkbox_' + i"
            cols="12"
            :sm="items[item.name].length - 1 >= 5 ? 12 : 6"
            :md="items[item.name].length - 1 >= 5 ? 4 : 12"
            class="mb-3"
          >
            <v-checkbox
              :label="$t(checkbox.text)"
              :input-value="selectedCheckbox(checkbox.value, formData[item.name])"
              hide-details
              color="blue"
              on-icon="mdi-checkbox-marked"
              class="mt-0 pt-0"
              @change="changeCheckbox(item.name, checkbox.value)"
            />
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="12">{{ $t('service.listIsEmpty') }}</v-col>
        </v-row>
        <p v-if="item.isDesc" class="font-14 mt-10">{{ $t('brandSafety.text') }}</p>
      </v-tab-item>
    </v-tabs-items>
  </v-sheet>
</template>
<script>
export default {
  name: 'CheckboxType',
  props: {
    panel: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    blockHeight: {
      type: [Number, String],
      default: 350,
    },
  },
  data: () => ({
    tab: null,
    tabs: [
      {
        id: 1,
        type: 'digital',
        name: 'digitalLabels',
        isSelected: true,
        isDesc: true,
      },
      {
        id: 2,
        type: 'sensitive',
        name: 'sensitiveCategories',
        isSelected: true,
        isDesc: true,
      },
      {
        id: 3,
        type: 'other',
      },
    ],
  }),
  methods: {
    selectedCheckbox(value, items) {
      return !!items.some((a) => a === value)
    },
    changeCheckbox(name, value) {
      this.$emit('change', name, value)
    },
  },
}
</script>
