<template>
  <div class="google-map">
    <GmapMap
      ref="map"
      :options="options"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="width: 100%; height: 600px"
    >
      <GmapCircle
        v-if="isNewMarker"
        :center="newMarker.coords"
        :radius="convertKmToM(newMarker.radius)"
        :options="setOptions(newMarker.location)"
      />
      <GmapCircle
        v-else
        v-for="m in markers"
        :key="m.id"
        :center="m.coords"
        :radius="convertKmToM(m.radius)"
        :editable="isEditable && m.id === editMarker.id"
        :draggable="isDraggable && m.id === editMarker.id"
        :options="setOptions(m.location)"
        @dragend="updateCoordinates($event, m)"
        @radius_changed="changedRadius($event, m)"
      />
    </GmapMap>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'GoogleMap',
  components: {},
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    editMarker: {
      type: Object,
      default: () => {},
    },
    isNewMarker: {
      type: Boolean,
      default: false,
    },
    newMarker: {
      type: Object,
      default: () => {},
    },
    isShowMap: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    center: {lat: 0, lng: 0},
    zoom: 2,
    options: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    },
    blueOptions: {
      strokeColor: '#0028ff',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#0028ff',
      fillOpacity: 0.3,
    },
    redOptions: {
      strokeColor: '#ff0000',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#ff0000',
      fillOpacity: 0.3,
    },
    isEditable: false,
    isDraggable: false,
  }),
  watch: {
    newMarker: {
      deep: true,
      handler(m) {
        if (m.id) {
          this.center = Object.assign({}, m.coords)
          this.zoom = 11
        }
      },
    },
    editMarker: {
      deep: true,
      handler(m) {
        if (m.id) {
          this.center = Object.assign({}, m.coords)
          this.isEditable = true
          this.isDraggable = true
        } else {
          this.isEditable = false
          this.isDraggable = false
        }
      },
    },
    isShowMap(val) {
      if (!val) {
        this.center = {lat: 0, lng: 0}
        this.zoom = 2
      }
    },
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    setOptions(location) {
      return location === 'target' ? this.blueOptions : this.redOptions
    },
    updateCoordinates(e, marker) {
      this.$emit('update', e, marker)
    },
    changedRadius(radius, marker) {
      const r = (+radius / 1000).toFixed(1)
      this.$emit('changedRadius', r, marker)
    },
    convertKmToM(nam) {
      return +nam * 1000
    },
  },
}
</script>
