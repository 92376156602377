export const locations = [
  {
    text: 'locationItems.target',
    value: 'target',
  },
  {
    text: 'locationItems.exception',
    value: 'exception',
  },
]
