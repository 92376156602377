export const PANEL_ITEMS = [
  {
    id: 1,
    type: 'basic',
    withPanelInfo: true,
  },
  {
    id: 2,
    type: 'geo',
    name: 'geoItems',
    valid: true,
    country: 'countryItems',
    withPanelInfo: true,
  },
  {
    id: 3,
    type: 'device',
    name: 'deviceTypesWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 4,
    type: 'iabWhite',
    name: 'iabWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 5,
    type: 'position',
    name: 'placementPositionWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 6,
    type: 'domain',
    name: 'domainWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 7,
    type: 'page',
    name: 'pageWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 8,
    type: 'carrier',
    name: 'carrierWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 9,
    type: 'model',
    name: 'makeWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 10,
    type: 'connection',
    name: 'connectionTypesWhiteItems',
    withPanelInfo: true,
  },
]

export const EXCEPTION_PANEL_ITEMS = [
  {
    id: 11,
    type: 'osBlack',
    name: 'osBlackItems',
    withPanelInfo: true,
  },
  {
    id: 12,
    type: 'iabBlack',
    name: 'iabBlackItems',
    withPanelInfo: true,
  },
  {
    id: 13,
    type: 'domainBlack',
    name: 'domainBlackItems',
    withPanelInfo: true,
  },
  {
    id: 14,
    type: 'pageBlack',
    name: 'pageBlackItems',
    withPanelInfo: true,
  },
  {
    id: 15,
    type: 'ipBlack',
    name: 'ipAddressBlackItems',
    withPanelInfo: true,
  },
]

export const ADVANCED_PANEL_ITEMS = [
  {
    id: 16,
    type: 'ipWhite',
    name: 'ipAddressWhiteItems',
    withPanelInfo: true,
  },
  {
    id: 17,
    type: 'safety',
    name: 'safetyItems',
    tabFirst: 'digitalLabels',
    tabSecond: 'sensitiveCategories',
    withPanelInfo: true,
  },
]
