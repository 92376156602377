<template>
  <div>
    <IconButton :small="false" @click="openMap">
      <v-icon size="18" class="mr-3">mdi-plus</v-icon>
      <v-icon>mdi-map</v-icon>
    </IconButton>
    <CustomDialog v-model="isShowMap" :isShowClose="false" persistent max-width="1600">
      <div class="my-10">
        <v-row class="mb-20">
          <v-col cols="12" sm="6">
            <v-row class="mb-10">
              <v-col cols="8" sm="5" md="5" class="mb-7">
                <MapAutocomplete
                  :isError="isError"
                  :isReset="isReset"
                  :disabled="isEditMarker"
                  @setCoords="setCityCoords"
                />
              </v-col>
              <v-col cols="8" sm="5" md="3" class="mb-7">
                <CustomInput
                  :value="newMarker.radius"
                  :label="$t('label.range')"
                  hide-details
                  :disabled="isEditMarker"
                  type="number"
                  step="0.1"
                  @input="setNewMarkerRadius"
                />
              </v-col>
              <v-col cols="8" sm="5" md="4" class="d-flex align-center mb-7">
                <CustomSelect
                  v-model="newMarker.location"
                  :items="locations"
                  hide-details
                  class="mr-5"
                  :disabled="isEditMarker"
                  @change="editLocationMarker"
                />
                <v-icon color="blue" :disabled="isEditMarker" @click="addMarkerToType">mdi-plus</v-icon>
              </v-col>
            </v-row>
            <div class="mb-15">
              <p>{{ $t('title.targetLocations') }}:</p>
              <MapItems
                type="target"
                label="label.target"
                :items="targetItems"
                :editMarker="editMarker"
                :locations="locations"
                @setRadius="setMarkerRadius"
                @setLocation="setLocationMarker"
                @edit="editMarkerCoords"
                @delete="deleteMarker"
              />
            </div>
            <div class="mb-15">
              <p>{{ $t('title.excludedLocations') }}:</p>
              <MapItems
                type="exception"
                label="label.excluded"
                :items="exceptionItems"
                :editMarker="editMarker"
                :locations="locations"
                @setRadius="setMarkerRadius"
                @setLocation="setLocationMarker"
                @edit="editMarkerCoords"
                @delete="deleteMarker"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <GoogleMap
              :newMarker="newMarker"
              :markers="markersItems"
              :editMarker="editMarker"
              :isNewMarker="isNewMarker"
              :isShowMap="isShowMap"
              @update="setMarkerCoords"
              @changedRadius="setMarkerRadius"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-center flex-wrap">
          <ActionButton class="mx-5 mb-5" @click="addAllMarkers">
            {{ $t('button.add') }}
          </ActionButton>
          <ActionButton color="error" class="mx-5 mb-5" @click="closeMap">
            {{ $t('button.close') }}
          </ActionButton>
        </div>
      </div>
    </CustomDialog>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import MapAutocomplete from '@/views/targets/map/MapAutocomplete'
import GoogleMap from '@/components/maps/GoogleMap'
import MapItems from '@/views/targets/map/MapItems'
import {locations} from '@/helpers/variables/locations'

const defaultNewMarker = {
  coords: {},
  radius: 10,
  location: 'target',
}

const defaultEditMarker = {
  id: '',
  place_id: '',
  city: '',
  coords: {},
  radius: 10,
  location: 'target',
}

export default {
  name: 'Map',
  components: {MapAutocomplete, GoogleMap, MapItems},
  data: () => ({
    isShowMap: false,
    isNewMarker: false,
    isError: false,
    isReset: false,
    newMarker: Object.assign({}, defaultNewMarker),
    editMarker: Object.assign({}, defaultEditMarker),
    locations: locations,
    markersItems: [],
    isEditMarker: false,
  }),
  computed: {
    targetItems() {
      return this.markersItems.filter((a) => a.location === 'target')
    },
    exceptionItems() {
      return this.markersItems.filter((a) => a.location === 'exception')
    },
  },
  methods: {
    ...mapActions({
      showToastError: 'SHOW_TOAST_ERROR',
    }),
    openMap() {
      this.isShowMap = true
    },
    setCityCoords(m) {
      this.isNewMarker = true
      this.newMarker.id = this.$uuidv4()
      this.newMarker.place_id = m.place_id
      this.newMarker.coords = m.coords
      this.newMarker.city = m.city
    },
    setNewMarkerRadius(r) {
      this.newMarker.radius = +r
    },
    setMarkerCoords(e, m) {
      const index = this.searchMarker(m)
      this.markersItems[index].coords = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      }
    },
    setMarkerRadius(r, m) {
      const index = this.searchMarker(m)
      this.markersItems[index].radius = +r
    },
    addMarkerToType() {
      if (Object.keys(this.newMarker.coords).length) {
        this.markersItems.push({
          id: this.newMarker.id,
          place_id: this.newMarker.place_id,
          coords: this.newMarker.coords,
          radius: this.newMarker.radius,
          city: this.newMarker.city,
          location: this.newMarker.location,
        })
        this.resetAllMarkers()
      } else {
        this.isError = true
      }
    },
    deleteMarker(m) {
      this.markersItems = this.markersItems.filter((a) => a.id !== m.id)
      this.isEditMarker = false
    },
    editMarkerCoords(m) {
      this.resetNewMarkers()
      this.isEditMarker = true
      if (this.editMarker.id !== m.id) {
        this.editMarker = m
      } else {
        this.resetAllMarkers()
      }
    },
    editLocationMarker(val) {
      if (this.isNewMarker) {
        this.newMarker.location = val
      } else {
        const index = this.searchMarker(this.editMarker)
        if (index !== -1) {
          this.markersItems[index].location = val
        }
      }
    },
    setLocationMarker(val, m) {
      const index = this.searchMarker(m)
      this.markersItems[index].location = val
    },
    searchMarker(m) {
      return this.markersItems.findIndex((a) => a.id === m.id)
    },
    resetAllMarkers() {
      this.editMarker = Object.assign({}, defaultEditMarker)
      this.isError = false
      this.isEditMarker = false
      this.resetNewMarkers()
    },
    resetNewMarkers() {
      this.newMarker = Object.assign({}, defaultNewMarker)
      this.isNewMarker = false
      this.isReset = true
      this.$nextTick(() => {
        this.isReset = false
      })
    },
    addAllMarkers() {
      if (this.markersItems.length) {
        this.$emit('addMarkers', this.markersItems)
        this.closeMap()
      } else {
        this.showToastError(this.$i18n.t('toast.chooseCity'))
      }
    },
    closeMap() {
      this.isShowMap = false
      this.resetAllMarkers()
      this.markersItems = []
    },
  },
}
</script>
