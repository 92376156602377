<template>
  <div class="virtual-scroll__custom" :class="type">
    <div v-if="!items.length" class="pa-5 greyDark--text">{{ $t(label) }}</div>
    <v-virtual-scroll :height="items.length ? 210 : 166" item-height="50" :items="items">
      <template #default="{item}">
        <div class="d-flex justify-space-between align-center px-8 py-4">
          <div class="d-flex align-center">
            <span class="mr-5">
              {{ item.city }}<span v-if="editMarker.id !== item.id">, {{ item.radius }}</span>
            </span>
            <v-sheet v-if="editMarker.id === item.id" width="240" class="d-flex align-center ml-10">
              <CustomInput
                :value="item.radius"
                hide-details
                type="number"
                step="0.1"
                class="virtual-scroll__input"
                @input="$emit('setRadius', $event, item)"
              />
              <v-btn
                plain
                color="blue"
                class="ml-2"
                @click="$emit('setLocation', replaceLocationName(item.location).value, item)"
              >
                {{ $t(replaceLocationName(item.location).text) }}
              </v-btn>
            </v-sheet>
          </div>
          <div>
            <v-icon color="red" class="mr-5" @click.stop="$emit('edit', item)">
              {{ editMarker.id !== item.id ? 'mdi-pencil' : 'mdi-content-save' }}
            </v-icon>
            <v-icon color="red" @click.stop="$emit('delete', item)">mdi-close-circle-outline</v-icon>
          </div>
        </div>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
export default {
  name: 'TargetMapItems',
  components: {},
  props: {
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    editMarker: {
      type: Object,
      default: () => {},
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  methods: {
    replaceLocationName(name) {
      return this.locations.find((a) => a.value !== name)
    },
  },
}
</script>
<style lang="scss">
.virtual-scroll {
  &__custom {
    border: 1px solid transparent;

    &.target {
      border-color: var(--v-blue-base);
    }

    &.exception {
      border-color: var(--v-red-base);
    }
  }

  &__input {
    &.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 28px;
    }
  }
}
</style>
