<template>
  <v-row>
    <v-col cols="6">
      <template v-if="inputType === 'search'">
        <CustomInput
          hide-details
          :label="$t(label)"
          class="mb-10"
          @input="searchItem(panel.name, panel.type, $event)"
        />

        <div
          v-if="isIab || isGroup"
          class="custom-virtual-scroll__container"
          :style="{maxHeight: blockHeight + 'px'}"
        >
          <template v-for="(item, index) in dictionary[panel.name]">
            <v-list v-if="item.isRoot" :key="item.id" class="py-1">
              <v-list-item v-if="item.isException" @click="selectItems(item)">
                <v-list-item-action>
                  <v-checkbox color="blue" :input-value="checkItems(item)" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.value }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group v-else no-action color="blue">
                <template #activator>
                  <v-list-item-action v-if="item.isValue !== false">
                    <v-checkbox
                      color="blue"
                      :input-value="checkItems(item)"
                      @click.stop="selectItems(item)"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="child in mapGroupItems(item.id)"
                  :key="child.id"
                  @click="selectItems(child)"
                >
                  <v-list-item-action>
                    <v-checkbox color="blue" :input-value="checkItems(child)" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="isIab">{{ $t(child.text) }}</v-list-item-title>
                    <v-list-item-title v-else>{{ child.value }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
            <div v-if="index === dictionary[panel.name].length - 1 && !isSearch" :key="'group_' + item.id">
              <Observer
                v-if="!isShowLoader && !dataPagination[panel.type].last"
                @loadMore="loadMore(panel.type, panel.name)"
              />
              <Loader v-if="isShowLoader && !dataPagination[panel.type].last" />
            </div>
          </template>
        </div>

        <div v-else-if="isModel">
          <v-tabs
            v-model="activeTab"
            mobile-breakpoint="0"
            slider-color="blue"
            color="blue"
            background-color="transparent"
            class="mb-5"
            grow
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.type"
              @click.once="tab.isServer ? changeActiveTab(tab.value) : {}"
            >
              <span v-if="tab.isTranslate">{{ $t('tabs.' + tab.type) }}</span>
              <span v-else>{{ tab.type }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" class="py-5">
            <v-tab-item v-for="tab in tabs" :key="tab.type">
              <div
                :key="tab.type"
                class="custom-virtual-scroll__container"
                :style="{maxHeight: blockHeight + 'px'}"
              >
                <template v-for="(item, index) in dictionary[tab.value]">
                  <v-list :key="item.id" class="py-1">
                    <v-list-group no-action color="blue">
                      <template #activator>
                        <v-list-item-content @click.once="loadTabItems(tab.value, item.value)">
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <div
                        :key="tab.type"
                        class="custom-virtual-scroll__container ma-5"
                        :style="{maxHeight: blockHeight + 'px'}"
                      >
                        <template v-for="(child, index) in dictionary[item.value]">
                          <v-list-item :key="child.model" @click="selectItems(child)">
                            <v-list-item-action>
                              <v-checkbox color="blue" :input-value="checkItems(child)" />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{ child.model }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <div
                            v-if="index === dictionary[item.value].length - 1 && !isSearch"
                            :key="'group_' + child.model"
                          >
                            <Observer
                              v-if="!isShowLoader && !dataPagination[item.value].last"
                              @loadMore="loadMoreTabChild(tab.value, item.value)"
                            />
                            <Loader v-if="isShowLoader && !dataPagination[item.value].last" />
                          </div>
                        </template>
                      </div>
                    </v-list-group>
                  </v-list>
                  <div
                    v-if="index === dictionary[tab.value].length - 1 && !isSearch"
                    :key="'service_' + item.id"
                  >
                    <Observer
                      v-if="!isShowLoader && !dataPagination[tab.value].last"
                      @loadMore="loadMore(panel.type, tab.value, true)"
                    />
                    <Loader v-if="isShowLoader && !dataPagination[tab.value].last" />
                  </div>
                </template>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <v-virtual-scroll
          v-else
          :items="dictionary[panel.name]"
          :height="blockHeight"
          :item-height="itemHeight"
        >
          <template v-slot="{item, index}">
            <v-list-item :value="item" color="blue" @click="selectItems(item)">
              <v-list-item-action>
                <v-checkbox color="blue" :input-value="checkItems(item)" />
              </v-list-item-action>
              <v-list-item-content color="blue">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div v-if="index === dictionary[panel.name].length - 1 && !isSearch">
              <Observer
                v-if="!isShowLoader && !dataPagination[panel.type].last"
                @loadMore="loadMore(panel.type, panel.name)"
              />
              <Loader v-if="isShowLoader && !dataPagination[panel.type].last" />
            </div>
          </template>
        </v-virtual-scroll>
      </template>
      <template v-else>
        <CustomInput
          v-model="addValue"
          hide-details
          :label="$t(label)"
          class="mb-20"
          @keydown.enter="addItem(panel.name, panel.type)"
        />

        <v-file-input
          v-model="file"
          :placeholder="$t(loadLabel)"
          height="50"
          :clearable="false"
          outlined
          hide-details
          accept="text/plain, .csv"
          @change="loadFiles(panel.name, panel.type)"
        />
        <p class="mt-5">{{ $t('service.fileDesc') }}</p>
      </template>
    </v-col>
    <v-col cols="6">
      <TargetSelected
        :panel="panel"
        :items="dictionary[panel.name]"
        :total="selectedItems.length"
        :selectedItems="selectedItems"
        :name="name"
        :deleteFiled="deleteFiled"
        :blockHeight="blockHeight"
        @delete="deleteItem"
        @clearAll="clearAllItems"
      />
    </v-col>
  </v-row>
</template>
<script>
import Observer from '@/components/loader/Observer'
import Loader from '@/components/loader/Loader'
import TargetSelected from '@/views/targets/part/TargetSelected'

export default {
  name: 'PanelType',
  components: {Observer, Loader, TargetSelected},
  props: {
    dictionary: {
      type: Object,
      default: () => {},
    },
    panel: {
      type: Object,
      default: () => {},
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    deleteFiled: {
      type: String,
      default: 'value',
    },
    actionFiled: {
      type: String,
      default: 'value',
    },
    isShowLoader: {
      type: Boolean,
      default: false,
    },
    dataPagination: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: 'service.labelByWord',
    },
    inputType: {
      type: String,
      default: 'search',
    },
    loadLabel: {
      type: String,
      default: 'label.loadFile',
    },
    blockHeight: {
      type: [Number, String],
      default: 500,
    },
    itemHeight: {
      type: [Number, String],
      default: 50,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isSearch: false,
    addValue: '',
    file: null,
    activeTab: 0,
  }),
  computed: {
    isIab() {
      return this.panel.type === 'iabWhite' || this.panel.type === 'iabBlack'
    },
    isGroup() {
      return this.panel.type === 'device' || this.panel.type === 'carrier' || this.panel.type === 'osBlack'
    },
    isModel() {
      return this.panel.type === 'model'
    },
    tab: {
      get() {
        return this.activeTab
      },
      set(value) {
        this.$emit('change', value)
      },
    },
  },
  methods: {
    selectItems(item) {
      this.$emit('select', this.name, this.actionFiled, item)
    },
    checkItems(item) {
      return this.selectedItems.some((a) => a[this.actionFiled] === item[this.actionFiled])
    },
    searchItem(name, type, value) {
      this.isSearch = !!value
      this.$emit('search', name, type, value)
    },
    addItem(name, type) {
      this.$emit('add', name, type, this.addValue)
      this.addValue = ''
    },
    loadFiles(name, type) {
      if (this.file) {
        this.$emit('load', name, type, this.file)
        this.$nextTick(() => {
          this.file = null
        })
      }
    },
    deleteItem(name, field, value) {
      this.$emit('delete', name, field, value)
    },
    clearAllItems(name) {
      this.$emit('clearAll', name)
    },
    loadMore(type, name, isTab) {
      this.$nextTick(() => {
        this.$emit('more', type, name, isTab)
      })
    },
    mapGroupItems(id) {
      const {name} = this.panel
      return this.dictionary[name].filter((a) => a.rootId === id)
    },
    changeActiveTab(tab) {
      this.$emit('changeTab', tab)
    },
    loadTabItems(type, value) {
      this.$nextTick(() => {
        this.$emit('loadTab', type, value)
      })
    },
    loadMoreTabChild(type, name) {
      this.$nextTick(() => {
        this.$emit('moreTab', type, name)
      })
    },
  },
}
</script>
