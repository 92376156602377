<template>
  <div>
    <div>{{ showInfo(panel) }}</div>
  </div>
</template>
<script>
export default {
  name: 'PanelInfoSelected',
  props: {
    panel: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    showInfo(panel) {
      const {type, name, country, tabFirst, tabSecond} = panel
      switch (type) {
        case 'basic':
          if (this.formData.name) {
            return this.formData.name
          } else {
            return this.$i18n.t('panelInfo.empty')
          }
        case 'geo':
          if (this.formData[country].length) {
            return this.mapInfo(this.formData[country], 'countryName')
          } else {
            return this.$i18n.t('panelInfo.empty')
          }
        case 'device':
          if (this.formData[name].length) {
            return this.mapInfo(this.formData[name], 'value')
          } else {
            return this.$i18n.t('panelInfo.device')
          }
        case 'carrier':
        case 'osBlack':
          if (this.formData[name].length) {
            return this.mapInfo(this.formData[name], 'value')
          } else {
            return this.$i18n.t('panelInfo.empty')
          }
        case 'iabWhite':
        case 'iabBlack':
          if (this.formData[name].length) {
            return this.mapInfo(this.formData[name], 'text', true)
          } else {
            return this.$i18n.t('panelInfo.empty')
          }
        case 'model':
          if (this.formData[name].length) {
            return this.mapInfo(this.formData[name], 'make')
          } else {
            return this.$i18n.t('panelInfo.empty')
          }
        case 'safety':
          if (this.formData[tabFirst].length || this.formData[tabSecond].length) {
            const items = [...this.formData[tabFirst], ...this.formData[tabSecond]]
            return this.mapInfo(items, '')
          } else {
            return this.$i18n.t('panelInfo.empty')
          }
        default:
          if (this.formData[name]?.length) {
            return this.mapInfo(this.formData[name], 'text')
          } else {
            return this.$i18n.t('panelInfo.empty')
          }
      }
    },
    mapInfo(items, value, isTranslate = false) {
      let name = ''
      if (value === 'countryName') {
        const {cityName, region, countryName} = items[0]
        const first = cityName ? cityName + ', ' : ''
        const second = region ? region + ', ' : ''
        name = first + second + countryName
      } else {
        name = value ? items[0][value] : items[0]
      }
      const amount = items.length > 1 ? items.length - 1 : ''
      if (isTranslate) {
        return amount ? this.$i18n.t(name) + ' +' + amount : this.$i18n.t(name)
      } else {
        return amount ? name + ' +' + amount : name
      }
    },
  },
}
</script>
