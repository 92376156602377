<template>
  <v-row>
    <v-col cols="6">
      <CustomInput
        hide-details
        :label="$t(label)"
        class="mb-10"
        @input="searchItem(panel.name, panel.type, $event)"
      />
      <v-tabs
        v-model="activeTab"
        mobile-breakpoint="0"
        slider-color="blue"
        color="blue"
        background-color="transparent"
        class="mb-5"
        grow
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.type"
          @click.once="tab.isServer ? changeActiveTab(tab.value) : {}"
        >
          <span v-if="tab.isTranslate">{{ $t('tabs.' + tab.type) }}</span>
          <span v-else>{{ tab.type }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" class="py-5">
        <v-tab-item v-for="tab in tabs" :key="tab.type">
          <div
            :key="tab.type"
            class="custom-virtual-scroll__container"
            :style="{maxHeight: blockHeight + 'px'}"
          >
            <template v-for="(item, index) in dictionary[tab.value]">
              <v-list :key="item.id" class="py-1">
                <v-list-item @click="selectItems(item)">
                  <v-list-item-action>
                    <v-checkbox color="blue" :input-value="checkItems(item)" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.make }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div v-if="index === dictionary[tab.value].length - 1 && !isSearch" :key="'target_' + item.id">
                <Observer
                  v-if="!isShowLoader && !dataPagination[tab.value].last"
                  @loadMore="loadMore(panel.type, tab.value, true)"
                />
                <Loader v-if="isShowLoader && !dataPagination[tab.value].last" />
              </div>
            </template>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <v-col cols="6">
      <TargetSelected
        :panel="panel"
        :items="dictionary[panel.name]"
        :total="selectedItems.length"
        :selectedItems="selectedItems"
        :name="name"
        :deleteFiled="deleteFiled"
        :blockHeight="blockHeight"
        @delete="deleteItem"
        @clearAll="clearAllItems"
      />
    </v-col>
  </v-row>
</template>
<script>
import Observer from '@/components/loader/Observer'
import Loader from '@/components/loader/Loader'
import TargetSelected from '@/views/targets/part/TargetSelected'

export default {
  name: 'ModelType',
  components: {Observer, Loader, TargetSelected},
  props: {
    dictionary: {
      type: Object,
      default: () => {},
    },
    panel: {
      type: Object,
      default: () => {},
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    deleteFiled: {
      type: String,
      default: 'make',
    },
    actionFiled: {
      type: String,
      default: 'make',
    },
    isShowLoader: {
      type: Boolean,
      default: false,
    },
    dataPagination: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: 'service.labelByWord',
    },
    blockHeight: {
      type: [Number, String],
      default: 500,
    },
    itemHeight: {
      type: [Number, String],
      default: 50,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isSearch: false,
    activeTab: 0,
  }),
  computed: {
    tab: {
      get() {
        return this.activeTab
      },
      set(value) {
        this.$emit('change', value)
      },
    },
  },
  methods: {
    selectItems(item) {
      this.$emit('select', this.name, this.actionFiled, item)
    },
    checkItems(item) {
      return this.selectedItems.some((a) => a[this.actionFiled] === item[this.actionFiled])
    },
    searchItem(name, type, value) {
      this.isSearch = !!value
      this.$emit('search', name, type, value)
    },
    deleteItem(name, field, value) {
      this.$emit('delete', name, field, value)
    },
    clearAllItems(name) {
      this.$emit('clearAll', name)
    },
    loadMore(type, name, isTab) {
      this.$nextTick(() => {
        this.$emit('more', type, name, isTab)
      })
    },
    changeActiveTab(tab) {
      this.$emit('changeTab', tab)
    },
  },
}
</script>
