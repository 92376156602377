<template>
  <v-row>
    <v-col cols="6">
      <v-autocomplete
        v-model="geo"
        :items="items"
        outlined
        dense
        clearable
        hide-details
        append-icon=""
        return-object
        :no-data-text="$t('service.listIsEmpty')"
        :label="$t(label)"
        :search-input.sync="search"
        no-filter
        @change="changeHandler"
      >
        <template #item="{item}">
          <div class="d-flex flex-column font-14 py-4">
            <span>{{ parseGeoName(item) }}</span>
            <span class="greyDark--text">{{ item.countryName }}</span>
          </div>
        </template>
      </v-autocomplete>
      <div class="custom-virtual-scroll__container" :style="{maxHeight: blockHeight + 'px'}">
        <v-list v-for="(item, index) in dictionary[panel.name]" :key="'country' + item.value">
          <v-list-group no-action color="blue">
            <template #activator>
              <v-list-item-content @click.once="loadCountryItems(item.value, 'country')">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list v-for="(country, countryIndex) in dictionary[item.value]" :key="country.id" class="mx-12">
              <v-list-group no-action color="blue">
                <template #activator>
                  <v-list-item-action>
                    <v-checkbox
                      color="blue"
                      :input-value="checkItems('id', country)"
                      @click.stop="selectItems('countryItems', 'id', country)"
                    />
                  </v-list-item-action>
                  <v-list-item-content @click.once="loadCountryItems(country.countryCode, 'region')">
                    <v-list-item-title>{{ country.countryName }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list
                  v-for="region in dictionary[country.countryCode]"
                  :key="region.regionCode"
                  class="mx-15 py-0"
                >
                  <v-list-group no-action color="blue">
                    <template #activator>
                      <v-list-item-action>
                        <v-checkbox
                          color="blue"
                          :input-value="checkItems('id', region)"
                          @click.stop="selectItems('countryItems', 'id', region)"
                        />
                      </v-list-item-action>
                      <v-list-item-content
                        @click.once="loadCountryItems(region.regionCode, 'city', country.countryCode)"
                      >
                        <v-list-item-title>{{ region.region }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <div
                      class="custom-virtual-scroll__container my-2 mx-15"
                      :style="{maxHeight: blockTabHeight + 'px'}"
                    >
                      <v-list
                        v-for="(city, cityIndex) in dictionary[region.regionCode]"
                        :key="city.id"
                        class="pa-0"
                      >
                        <v-list-item @click="selectItems('countryItems', 'id', city)">
                          <v-list-item-action>
                            <v-checkbox color="blue" :input-value="checkItems('id', city)" />
                          </v-list-item-action>
                          <v-list-item-title>{{ city.cityName }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="cityIndex === dictionary[region.regionCode].length - 1 && !isSearch">
                          <Observer
                            v-if="!isShowLoader && !dataPagination[region.regionCode].last"
                            @loadMore="loadMoreCountryItems(region.regionCode, 'city', country.countryCode)"
                          />
                          <Loader v-if="isShowLoader && !dataPagination[region.regionCode].last" />
                        </div>
                      </v-list>
                    </div>
                  </v-list-group>
                </v-list>
              </v-list-group>
              <div v-if="countryIndex === dictionary[item.value].length - 1 && !isSearch">
                <Observer
                  v-if="!isShowLoader && !dataPagination[item.value].last"
                  @loadMore="loadMoreCountryItems(item.value, 'country')"
                />
                <Loader v-if="isShowLoader && !dataPagination[panel.type].last" />
              </div>
            </v-list>
          </v-list-group>
          <div v-if="index === dictionary[panel.name].length - 1 && !isSearch">
            <Observer
              v-if="!isShowLoader && !dataPagination[panel.type].last"
              @loadMore="loadMore(panel.type, panel.name)"
            />
            <Loader v-if="isShowLoader && !dataPagination[panel.type].last" />
          </div>
        </v-list>
      </div>
    </v-col>
    <v-col cols="6">
      <TargetSelected
        :panel="panel"
        :items="dictionary[panel.name]"
        :total="selectedItems.length"
        :selectedItems="selectedItems"
        :name="name"
        :deleteFiled="deleteFiled"
        :blockHeight="blockHeight"
        @delete="deleteItem"
        @clearAll="clearAllItems"
      />
    </v-col>
  </v-row>
</template>
<script>
import Observer from '@/components/loader/Observer'
import Loader from '@/components/loader/Loader'
import TargetSelected from '@/views/targets/part/TargetSelected'
import axios from '@/plugins/axios'

export default {
  name: 'GeoType',
  components: {Observer, Loader, TargetSelected},
  props: {
    dictionary: {
      type: Object,
      default: () => {},
    },
    panel: {
      type: Object,
      default: () => {},
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    deleteFiled: {
      type: String,
      default: 'id',
    },
    actionFiled: {
      type: String,
      default: 'id',
    },
    isShowLoader: {
      type: Boolean,
      default: false,
    },
    dataPagination: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: 'service.labelGeo',
    },
    blockHeight: {
      type: [Number, String],
      default: 350,
    },
    blockTabHeight: {
      type: [Number, String],
      default: 250,
    },
    itemHeight: {
      type: [Number, String],
      default: 50,
    },
  },
  data: () => ({
    isSearch: false,
    geo: null,
    search: null,
    items: [],
  }),
  watch: {
    search(value) {
      if (!value || value.length < 3) {
        this.items = []
        return
      }
      setTimeout(() => {
        this.searchHandler(value)
      }, 100)
    },
  },
  methods: {
    loadCountryItems(value, type, countryCode) {
      this.$emit('loadCountry', value, type, countryCode)
    },
    selectItems(name, field, item) {
      this.$emit('select', name, field, item)
    },
    checkItems(field, item) {
      return this.selectedItems.some((a) => a[field] === item[field])
    },
    deleteItem(name, field, value) {
      this.$emit('delete', name, field, value)
    },
    clearAllItems(name) {
      this.$emit('clearAll', name)
    },
    loadMore(type, name) {
      this.$nextTick(() => {
        this.$emit('more', type, name)
      })
    },
    loadMoreCountryItems(value, type, countryCode) {
      this.$nextTick(() => {
        this.$emit('loadMoreCountry', value, type, countryCode)
      })
    },
    async searchHandler(value) {
      const params = {
        page: 0,
        size: 5,
        sort: 'cityCode,asc',
        value,
      }
      const resp = await axios.get('dictionary/target/geo-search/read-all', {params})
      if (resp) {
        const {content} = resp.data
        this.items = content
      }
    },
    changeHandler() {
      if (typeof this.geo === 'object') {
        this.selectItems('countryItems', 'id', this.geo)
        this.reset()
      }
    },
    reset() {
      this.geo = null
      this.search = null
      this.items = []
    },
    parseGeoName(item) {
      const {cityName, region} = item
      const name = cityName ? cityName + ', ' : ''
      return name + region
    },
  },
}
</script>
