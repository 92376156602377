<template>
  <div>
    <TargetPanels
      :id="id"
      ref="targetForm"
      :formData="formData"
      :panels="panelItems"
      :exceptionPanelItems="exceptionPanelItems"
      :advancedPanelItems="advancedPanelItems"
      :statusItems="statusItems"
      :dictionaryTypes="dictionaryTypes"
      :isAdmin="isAdmin"
      :isManager="isManager"
    />
    <div class="d-sm-flex justify-space-between my-25">
      <ActionButton outlined color="error" class="mb-5 mb-sm-0" @click="backHandler">
        {{ $t('button.back') }}
      </ActionButton>
      <div v-if="permission.update" class="d-sm-flex align-end">
        <v-slide-x-reverse-transition hide-on-leave>
          <ActionButton outlined :loading="actionLoading" color="green" @click="save">
            {{ $t('button.save') }}
          </ActionButton>
        </v-slide-x-reverse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import TargetPanels from '@/views/targets/part/TargetPanels'

import {PANEL_ITEMS, EXCEPTION_PANEL_ITEMS, ADVANCED_PANEL_ITEMS} from '@/helpers/variables/paneltems'
import {STATUS_ITEMS} from '@/helpers/variables/statusItems'
import {DICTIONARY_TYPES} from '@/helpers/variables/dictionaryTypes'
import {backHandler} from '@/helpers/handler/backHandler'

export default {
  name: 'TargetDetail',
  components: {TargetPanels},
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    formData: {
      status: 'ACTIVE',
      countryItems: [],
      deviceTypesWhiteItems: [],
      iabWhiteItems: [],
      placementPositionWhiteItems: [],
      domainWhiteItems: [],
      carrierWhiteItems: [],
      makeWhiteItems: [],
      connectionTypesWhiteItems: [],
      geoBoundariesWhiteItems: [],
      geoBoundariesBlackItems: [],
      osBlackItems: [],
      iabBlackItems: [],
      domainBlackItems: [],
      ipAddressWhiteItems: [],
      ipAddressBlackItems: [],
      digitalLabels: [],
      sensitiveCategories: [],
      pageWhiteItems: [],
      pageBlackItems: [],
    },
    panelItems: PANEL_ITEMS,
    exceptionPanelItems: EXCEPTION_PANEL_ITEMS,
    advancedPanelItems: ADVANCED_PANEL_ITEMS,
    statusItems: STATUS_ITEMS,
    dictionaryTypes: DICTIONARY_TYPES,
  }),
  computed: {
    ...mapState({
      item: (state) => state.target.item,
      actionLoading: (state) => state.target.actionLoading,
      isCheckLocale: (state) => state.isCheckLocale,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      permission: 'target/permission',
    }),
  },
  methods: {
    ...mapActions({
      getItemById: 'target/GET_ITEM_BY_ID',
      createTarget: 'target/CREATE_TARGET',
      editTarget: 'target/EDIT_TARGET',
    }),
    backHandler,
    async loadData() {
      await this.getItemById(this.id)
      await this.setFormData()
    },
    setFormData() {
      const {
        userId,
        name,
        status,
        geoList,
        deviceTypesWhitelist,
        iabWhitelist,
        placementPositionWhitelist,
        domainWhitelist,
        pageWhitelist,
        pageBlacklist,
        carrierWhitelist,
        makeWhitelist,
        connectionTypesWhitelist,
        osBlacklist,
        iabBlacklist,
        domainBlacklist,
        ipAddressWhitelist,
        ipAddressBlacklist,
        digitalLabels,
        sensitiveCategories,
        geoBoundariesWhitelist,
        geoBoundariesBlacklist,
      } = this.item

      this.formData = {
        ...this.formData,
        userId,
        name,
        status,
        countryItems: geoList,
        geoBoundariesWhiteItems: this.mapFormData(geoBoundariesWhitelist, 'boundaries'),
        geoBoundariesBlackItems: this.mapFormData(geoBoundariesBlacklist, 'boundaries'),
        deviceTypesWhiteItems: deviceTypesWhitelist.map((a) => ({
          text: a.type,
          value: a.text,
        })),
        iabWhiteItems: iabWhitelist.map((a) => ({
          text: a.text,
          value: a.value,
        })),
        placementPositionWhiteItems: placementPositionWhitelist,
        domainWhiteItems: this.mapFormData(domainWhitelist),
        pageWhiteItems: this.mapFormData(pageWhitelist),
        pageBlackItems: this.mapFormData(pageBlacklist),
        carrierWhiteItems: carrierWhitelist.map((a) => ({
          text: a.countryName,
          value: a.name,
        })),
        makeWhiteItems: makeWhitelist,
        connectionTypesWhiteItems: connectionTypesWhitelist,
        osBlackItems: osBlacklist.map((a) => ({
          text: a.type,
          value: a.text,
        })),
        iabBlackItems: iabBlacklist.map((a) => ({
          text: a.text,
          value: a.value,
        })),
        domainBlackItems: this.mapFormData(domainBlacklist),
        ipAddressWhiteItems: this.mapFormData(ipAddressWhitelist),
        ipAddressBlackItems: this.mapFormData(ipAddressBlacklist),
        digitalLabels: digitalLabels,
        sensitiveCategories: sensitiveCategories,
      }
    },
    mapFormData(items, type = 'default') {
      switch (type) {
        case 'default':
          return items.map((a) => ({
            text: a,
          }))
        case 'boundaries':
          return items.map((a) => ({
            id: this.$uuidv4(),
            city: a.city,
            coords: {
              lat: a.latitude,
              lng: a.longitude,
            },
            place_id: a.placeId,
            radius: a.range,
          }))
        default:
          return []
      }
    },
    async save() {
      if (this.$refs.targetForm.validate()) {
        const formData = this.parseFormData(this.formData)
        const resp = this.id
          ? await this.editTarget({id: this.id, formData: formData})
          : await this.createTarget(formData)
        if (resp?.status === 200) await this.backHandler()
      }
    },
    parseFormData(data) {
      return {
        carrierWhitelist: data.carrierWhiteItems.map((a) => ({countryName: a.text, name: a.value})),
        connectionTypesWhitelist: data.connectionTypesWhiteItems,
        geoList: data.countryItems,
        deviceTypesWhitelist: data.deviceTypesWhiteItems.map((a) => ({type: a.text, text: a.value})),
        digitalLabels: data.digitalLabels,
        domainBlacklist: data.domainBlackItems.map((a) => a.text),
        domainWhitelist: data.domainWhiteItems.map((a) => a.text),
        geoBoundariesBlacklist: data.geoBoundariesBlackItems.map((a) => ({
          city: a.city,
          latitude: a.coords.lat,
          longitude: a.coords.lng,
          placeId: a.place_id,
          range: a.radius,
        })),
        geoBoundariesWhitelist: data.geoBoundariesWhiteItems.map((a) => ({
          city: a.city,
          latitude: a.coords.lat,
          longitude: a.coords.lng,
          placeId: a.place_id,
          range: a.radius,
        })),
        iabBlacklist: data.iabBlackItems,
        iabWhitelist: data.iabWhiteItems,
        ipAddressBlacklist: data.ipAddressBlackItems.map((a) => a.text),
        ipAddressWhitelist: data.ipAddressWhiteItems.map((a) => a.text),
        makeWhitelist: data.makeWhiteItems,
        name: data.name,
        osBlacklist: data.osBlackItems.map((a) => ({type: a.text, text: a.value})),
        pageWhitelist: data.pageWhiteItems.map((a) => a.text),
        pageBlacklist: data.pageBlackItems.map((a) => a.text),
        placementPositionWhitelist: data.placementPositionWhiteItems,
        sensitiveCategories: data.sensitiveCategories,
        status: data.status,
        userId: data.userId,
      }
    },
    reset() {
      this.$refs.targetForm.resetValidation()
      this.edit()
    },
  },
  mounted() {
    if (this.id) this.loadData()
  },
}
</script>
