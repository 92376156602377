<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-12">
      <div class="font-weight-bold">{{ $t('service.selected') }}: {{ total }}</div>
      <v-btn text color="blue" @click="clearAllItems(name)">
        {{ $t('button.clearAll') }}
      </v-btn>
    </div>
    <v-virtual-scroll :items="selectedItems" :height="blockHeight" :item-height="itemHeight">
      <template v-slot="{item}">
        <v-list-item>
          <template v-if="isGeo">{{ showGeoInfo(item) }}</template>

          <template v-else-if="isIab || isGroup">
            <div>
              <div v-if="findMainGroup(item)" class="font-14 greyDark--text">{{ findMainGroup(item) }}</div>
              <div v-if="isIab">{{ $t(item.text) }}</div>
              <div v-else>{{ item.value }}</div>
            </div>
          </template>

          <template v-else-if="isModel">
            <div>
              <div class="font-14 greyDark--text">{{ item.type }}</div>
              <div>{{ item.make }}</div>
            </div>
          </template>

          <template v-else>{{ item.text }}</template>
          <v-icon color="red" class="ml-10" @click="deleteItem(name, deleteFiled, item)">
            mdi-close-circle-outline
          </v-icon>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
export default {
  name: 'TargetSelected',
  props: {
    panel: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    deleteFiled: {
      type: String,
      default: 'value',
    },
    blockHeight: {
      type: [Number, String],
      default: 500,
    },
    itemHeight: {
      type: [Number, String],
      default: 50,
    },
  },
  computed: {
    isGeo() {
      return this.panel.type === 'geo'
    },
    isIab() {
      return this.panel.type === 'iabWhite' || this.panel.type === 'iabBlack'
    },
    isGroup() {
      return this.panel.type === 'device' || this.panel.type === 'carrier' || this.panel.type === 'osBlack'
    },
    isModel() {
      return this.panel.type === 'model'
    },
  },
  methods: {
    deleteItem(name, field, value) {
      this.$emit('delete', name, field, value)
    },
    clearAllItems(name) {
      this.$emit('clearAll', name)
    },
    findMainGroup(item) {
      return !item.isRoot ? this.$t(this.items.find((a) => a.id === item.rootId)?.text) : null
    },
    showGeoInfo(item) {
      if (item.cityName && item.region) {
        return item.cityName + ', ' + item.region + ', ' + item.countryName
      } else if (item.region) {
        return item.region + ', ' + item.countryName
      } else {
        return item.countryName
      }
    },
  },
}
</script>
